import { isGlobalworth } from 'config'
import { useRemoteConfigContext } from 'shared/components'
import {
  getLocalStorageString,
  ItemTag,
  setLocalStorageValue,
} from 'shared/utils/localStorageUtils'
import { useCurrentUser } from 'shared/data'
import { useUpdateUserPreferences } from 'modules/home/data'
import { useTranslation } from 'react-i18next'
import { Languages } from 'shared/types'

export const useConsents = () => {
  const { i18n } = useTranslation()
  const remoteConfig = useRemoteConfigContext()
  const {
    data: user,
    refetch: refetchCurrentUser,
    loading: loadingCurrentUser,
  } = useCurrentUser()
  const [updateUserPreferences, { loading: loadingUpdatePreferences }] =
    useUpdateUserPreferences()

  const remotePrivacyPolicyVersion = () =>
    isGlobalworth
      ? remoteConfig?.globalworth_privacy_policy_version
      : remoteConfig?.bluebolt_privacy_policy_version

  const remoteRegulationsVersion = () =>
    isGlobalworth
      ? remoteConfig?.globalworth_regulations_version
      : remoteConfig?.bluebolt_regulations_version

  const localPrivacyPolicyVersion = () =>
    getLocalStorageString(
      isGlobalworth
        ? ItemTag.GLOBALWORTH_PRIVACY_POLICY_VERSION
        : ItemTag.BLUEBOLT_PRIVACY_POLICY_VERSION
    )

  const localRegulationsVersion = () =>
    getLocalStorageString(
      isGlobalworth
        ? ItemTag.GLOBALWORTH_REGULATIONS_VERSION
        : ItemTag.BLUEBOLT_REGULATIONS_VERSION
    )

  const REGULATIONS_LINK =
    i18n.language === Languages.POLISH
      ? isGlobalworth
        ? remoteConfig?.globalworth_pl_regulations_url
        : remoteConfig?.bluebolt_pl_regulations_url
      : isGlobalworth
      ? remoteConfig?.globalworth_en_regulations_url
      : remoteConfig?.bluebolt_en_regulations_url

  const PRIVACY_POLICY_LINK = isGlobalworth
    ? remoteConfig?.globalworth_privacy_policy_url
    : remoteConfig?.bluebolt_privacy_policy_url

  const marketingFeatureEnabled = () => isGlobalworth

  const hasAcceptedCurrentPrivacyPolicy = (): boolean => {
    const remoteValue = remotePrivacyPolicyVersion()
    if (remoteValue === undefined) return true
    return remoteValue === localPrivacyPolicyVersion()
  }

  const hasAcceptedCurrentRegulations = (): boolean => {
    const remoteValue = remoteRegulationsVersion()
    if (remoteValue === undefined) return true
    return remoteValue === localRegulationsVersion()
  }

  const hasAcceptedAllCurrentPreferences = (): boolean =>
    hasAcceptedCurrentPrivacyPolicy() && hasAcceptedCurrentRegulations()

  const showMarketingConsentPopup = (): boolean =>
    marketingFeatureEnabled() &&
    user?.viewer.viewerPreferences.marketingConsentAccepted === null

  const setLocalPreferencsByRemoteConfig = (): void => {
    const remotePP = remotePrivacyPolicyVersion()
    if (remotePP !== undefined)
      setLocalStorageValue(
        isGlobalworth
          ? ItemTag.GLOBALWORTH_PRIVACY_POLICY_VERSION
          : ItemTag.BLUEBOLT_PRIVACY_POLICY_VERSION,
        remotePP
      )
    const remoteRegulations = remoteRegulationsVersion()
    if (remoteRegulations !== undefined)
      setLocalStorageValue(
        isGlobalworth
          ? ItemTag.GLOBALWORTH_REGULATIONS_VERSION
          : ItemTag.BLUEBOLT_REGULATIONS_VERSION,
        remoteRegulations
      )
  }

  const setMarketingConsentChoice = async (
    accepted: boolean
  ): Promise<boolean> => {
    const response = await updateUserPreferences({
      variables: { input: { marketingConsentAccepted: accepted } },
    })
    if (!response.errors) {
      await refetchCurrentUser()
      return true
    }
    return false
  }

  return {
    remotePrivacyPolicyVersion,
    remoteRegulationsVersion,
    localPrivacyPolicyVersion,
    localRegulationsVersion,
    marketingFeatureEnabled,
    hasAcceptedCurrentPrivacyPolicy,
    hasAcceptedCurrentRegulations,
    hasAcceptedAllCurrentPreferences,
    showMarketingConsentPopup,
    setLocalPreferencsByRemoteConfig,
    setMarketingConsentChoice,
    loading: loadingCurrentUser || loadingUpdatePreferences,
    marketingConsentAccepted:
      user?.viewer.viewerPreferences.marketingConsentAccepted ?? false,
    regulationsLink: REGULATIONS_LINK,
    privacyPolicyLink: PRIVACY_POLICY_LINK,
  }
}

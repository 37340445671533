import {
  useFormContext,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import {
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
} from '@mui/material'

export interface CounterProps {
  showCounter: boolean
  maxLength: number
}

export type TextFieldProps = Partial<BaseTextFieldProps> & {
  name: UseControllerProps['name']
  counterProps?: CounterProps
  defaultValue?: string
  hintText?: string
}

const TextField = ({
  name,
  label,
  defaultValue = '',
  hintText = undefined,
  counterProps,
  ...props
}: TextFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  let helperText = counterProps
    ? error
      ? error.message
      : typeof inputProps.value === 'string'
        ? `${inputProps.value.length}/${counterProps.maxLength}`
        : undefined
    : error?.message

  if (helperText === undefined && hintText)
    helperText = hintText

  return (
    <BaseTextField
      label={label}
      inputRef={ref}
      error={!!error}
      helperText={helperText}
      {...inputProps}
      {...props}
    />
  )
}

export default TextField

export const enum ItemTag {
  GLOBALWORTH_PRIVACY_POLICY_VERSION = 'GLOBALWORTH_PRIVACY_POLICY_VERSION',
  GLOBALWORTH_REGULATIONS_VERSION = 'GLOBALWORTH_REGULATIONS_VERSION',
  BLUEBOLT_PRIVACY_POLICY_VERSION = 'BLUEBOLT_PRIVACY_POLICY_VERSION',
  BLUEBOLT_REGULATIONS_VERSION = 'BLUEBOLT_REGULATIONS_VERSION',
}

export const getLocalStorageNumber = (
  itemTag: ItemTag | string,
  defaultValue: number | null = null
): number | null => {
  const value = localStorage.getItem(itemTag)
  return value !== null ? Number(value) : defaultValue
}

export const getLocalStorageString = (
  itemTag: ItemTag | string
): string | null => localStorage.getItem(itemTag)

export const setLocalStorageValue = (
  itemTag: ItemTag | string,
  value: string | number
): void => localStorage.setItem(itemTag, value.toString())
